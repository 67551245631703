import { AccessControlProvider, Refine, ResourceProps } from '@refinedev/core';
import routerBindings from '@refinedev/react-router-v6';

import {
  tmsServiceProvider,
  accessControlProvider as defaultAccessControlProvider,
  authProvider,
  coreServiceProviderName,
  coreServiceDataProvider,
  useNotificationProvider,
} from '../providers';

export type RefineProviderProps = {
  resources?: ResourceProps[];
  children?: React.ReactNode;
  accessControlProvider?: AccessControlProvider;
};

export const RefineProvider = ({
  resources,
  children,
  accessControlProvider = defaultAccessControlProvider,
}: RefineProviderProps) => {
  const [notificationProvider] = useNotificationProvider();
  return (
    <Refine
      dataProvider={{
        default: tmsServiceProvider,
        [coreServiceProviderName]: coreServiceDataProvider,
      }}
      notificationProvider={notificationProvider}
      routerProvider={routerBindings}
      authProvider={authProvider}
      accessControlProvider={accessControlProvider}
      resources={resources}
      options={{
        syncWithLocation: true,
        warnWhenUnsavedChanges: true,
      }}
    >
      {children}
    </Refine>
  );
};
